import QueryFilter from 'components/Table/components/QueryFilter'
import IconButton from '@mui/material/IconButton'

import { EXCHANGE_ACTION } from './hooks'

const renderFilter = (info) => <QueryFilter key='FilterInput' {...info} />

export const getColumns = (networksMap, setSelectedExchange) => ([
  {
    Header: '',
    accessor: '_update-action',
    Cell: ({ row }) => {
      return (
        <IconButton
          onClick={() => {
            setSelectedExchange({ ...row.original, _type: EXCHANGE_ACTION.UPDATE })
          }}
          size={'small'}
          style={{ margin: '0 8px', color: '#1976d2' }}
        >
          <i className='fas fa-edit' />
        </IconButton>
      )
    },
    disableFilters: true,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Filter: renderFilter,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Filter: renderFilter,
  },
  {
    Header: 'Internal Name',
    accessor: 'internal_name',
    Filter: renderFilter,
  },
  {
    Header: 'Is DEX',
    accessor: 'is_dex',
    Cell: ({ value }) => value.toString(),
    Filter: renderFilter,
  },
  {
    Header: 'Network',
    accessor: 'network_name',
    Cell: ({ value }) => value,
    Filter: renderFilter,
  },
  {
    Header: 'Available',
    accessor: 'available',
    Cell: ({ value }) => value.toString(),
    Filter: renderFilter,
  },
  {
    Header: '',
    accessor: '_remove-action',
    Cell: ({ row }) => {
      return (
        <IconButton
          onClick={() => {
            setSelectedExchange({ ...row.original, _type: EXCHANGE_ACTION.REMOVE })
          }}
          size={'small'}
          style={{ margin: '0 8px', color: 'red' }}
        >
          <i className='fas fa-trash' />
        </IconButton>
      )
    },
    disableFilters: true,
  },
])
