import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'

import balancesApi from 'api/balances-api'

export const useBalanceData = (hideZeroBalances) => {
  const [data, setData] = useState([])
  const [currencies, setСurrencies] = useState([])
  const [brokerWallets, setBrokerWallets] = useState({})
  const [isFetching, setIsFetching] = useState(false)

  const callApi = useCallback(async () => {
    setIsFetching(true)
    const response = await balancesApi.getBalance()

    if (!response) {
      setIsFetching(false)

      return
    }

    const { cexAssets, dexAssets } = response

    const balances = {}
    const brokersSet = new Set()
    const brokerWallets = {}
    const totalBalancesByBroker = {
      name: 'TOTAL',
      total: new BigNumber('0'),
    }

    for (const balance of cexAssets) {
      if (hideZeroBalances && balance.balanceUsdt === '0') {
        continue
      }

      brokersSet.add(balance.exchange)

      totalBalancesByBroker.total = totalBalancesByBroker.total.plus(new BigNumber(balance.balanceUsdt || '0'))

      if (!totalBalancesByBroker[balance.exchange]) {
        totalBalancesByBroker[balance.exchange] = {
          balanceUsdtBigNumber: new BigNumber(balance.balanceUsdt || '0'),
        }
      } else {
        // eslint-disable-next-line max-len
        totalBalancesByBroker[balance.exchange].balanceUsdtBigNumber = totalBalancesByBroker[balance.exchange].balanceUsdtBigNumber.plus(new BigNumber(balance.balanceUsdt || '0'))
      }

      if (!balances[balance.name]) {
        balances[balance.name] = {
          name: balance.name,
          [balance.exchange]: balance,
          total: new BigNumber(balance.balanceUsdt || '0'),
        }
      } else {
        balances[balance.name][balance.exchange] = balance
        balances[balance.name].total = balances[balance.name].total.plus(new BigNumber(balance.balanceUsdt || '0'))
      }
    }

    for (const balance of dexAssets) {
      if (hideZeroBalances && balance.balanceUsdt === '0') {
        continue
      }

      brokersSet.add(balance.network)
      brokerWallets[balance.network] = balance.dexWallet

      totalBalancesByBroker.total = totalBalancesByBroker.total.plus(new BigNumber(balance.balanceUsdt || '0'))

      if (!totalBalancesByBroker[balance.network]) {
        totalBalancesByBroker[balance.network] = {
          balanceUsdtBigNumber: new BigNumber(balance.balanceUsdt || '0'),
        }
      } else {
        // eslint-disable-next-line max-len
        totalBalancesByBroker[balance.network].balanceUsdtBigNumber = totalBalancesByBroker[balance.network].balanceUsdtBigNumber.plus(new BigNumber(balance.balanceUsdt || '0'))
      }

      if (!balances[balance.name]) {
        balances[balance.name] = {
          name: balance.name,
          [balance.network]: balance,
          total: new BigNumber(balance.balanceUsdt || '0'),
        }
      } else {
        balances[balance.name][balance.network] = balance
        balances[balance.name].total = balances[balance.name].total.plus(new BigNumber(balance.balanceUsdt || '0'))
      }
    }

    setData([totalBalancesByBroker, ...Object.values(balances)])
    setСurrencies([...brokersSet])
    setBrokerWallets(brokerWallets)
    setIsFetching(false)
  }, [hideZeroBalances])

  useEffect(() => {
    callApi()
  }, [callApi])

  return [data, currencies, brokerWallets, isFetching]
}
