import { useState } from 'react'
import { useSelector } from 'react-redux'
import { networksListSelector } from 'redux/selectors/settings'
import styled from '@emotion/styled'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

const AutocompleteContainer = styled.div`
  padding: 10px;
`

const Focus = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: grey;
  z-index: 3;
  opacity: .7;
  transition: all .3s ease;
`

const StyledAutocomplete = styled(Autocomplete)`
  z-index: 4;
  position: relative;
  background: #fff;
  border-radius: 4px;
`

export const NetworksSelector = ({ selectedNetwork, onSelectNetwork }) => {
  const [focused, onFocus] = useState(!!selectedNetwork)
  const networks = useSelector(networksListSelector)

  return (
    <AutocompleteContainer>
      {!focused && <Focus />}
      <StyledAutocomplete
        disablePortal
        openOnFocus
        blurOnSelect
        value={selectedNetwork}
        onChange={(_, newValue) => {
          onSelectNetwork(newValue)
        }}
        isOptionEqualToValue={({ id }) => id === selectedNetwork.id}
        onFocus={() => onFocus(true)}
        getOptionLabel={(option) => option.name}
        options={networks}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Select Network" />}
        size="small"
      />
    </AutocompleteContainer>
  )
}
