import { Paper, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import Table from 'components/Table/Table'
import useFullscreen from 'hooks/use-fullscreen'

import botAPI from '../../api/bot-api'
import { useInterval } from '../../hooks/use-interval'

import s from './BotOpportunities.module.css'
import { COLUMNS } from './columns'

import { Pagination, usePagination } from 'components/Pagination'

const BotOpportunities = () => {
  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const { page, increment, decrement, pageSize, changePageSize, tableRef, isLastPage, setLastPage } = usePagination()

  useFullscreen()

  const fetchData = async () => {
    const response = await botAPI.getOpportunities(page, pageSize)

    const arr = response
      ? Object.keys(response)
        .map((key) =>
          response[key].map((item) => ({
            ...item,
            cex1Symbol: item.cex1SymbolLeft + item.cex1SymbolRight,
            dexSymbol: item.dexSymbolLeft + item.dexSymbolRight,
          }))
        )
        .flat()
      : []

    setLastPage(arr?.length < pageSize)

    if (arr && arr?.length > 0) {
      setData(arr)
    }
    setIsFetching(false)
  }

  useInterval(fetchData, 1000)

  useEffect(() => {
    setIsFetching(true)
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize])

  return (
    <Paper className={s.paper} elevation={2} id='bot_opportunities_table'>
      <Typography variant={'h5'} style={{ padding: '0 10px' }}>
        Bot Opportunities
      </Typography>
      <Table tableRef={tableRef} data={data} columns={columns} loading={isFetching} enableFilters />
      <Pagination
        page={page}
        increment={increment}
        decrement={decrement}
        pageSize={pageSize}
        changePageSize={changePageSize}
        tableRef={tableRef}
        isLastPage={isLastPage}
      />
    </Paper>
  )
}

export default BotOpportunities
