import { fetch } from '.'

export const opportunitiesApi = {
  getCountList: () => {
    return fetch
      .get(`opportunities-count`)
      .json()
      .catch((err) => console.log(err))
  },
}
