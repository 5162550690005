import { fetch } from '.'

export const ordersApi = {
  getOrdersCountList: () => {
    return fetch
      .get(`orders-any-spend-count`)
      .json()
      .catch((err) => console.log(err))
  },
}
