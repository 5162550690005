import { pickBy } from 'lodash'
import { fetch } from './'

export const balancesApi = {
  getBalances: (network) => {
    return fetch
      .get(`get-balances`, { searchParams: { network } })
      .json()
      .catch((err) => console.log(err))
  },
  getBalance: () => {
    return fetch
      .get(`balance`)
      .json()
      .catch((err) => console.log(err))
  },
  getExpectedBalance: () => {
    return fetch
      .get(`expected-balance`)
      .json()
      .catch((err) => console.log(err))
  },
  getBalanceLeveling: (page, pageSize, sortBy, filter) => {
    const sortedField = sortBy?.id
    const sortedDirection = sortBy?.desc == null ? undefined : sortBy?.desc ? 'desc' : 'asc'
    const filteredField = filter?.id
    const filteredValue = filter?.value
    const params = {
      sortedField,
      sortedDirection,
      filteredField,
      filteredValue,
    }

    return fetch
      .get(`balance-leveling`, {
        searchParams: {
          page,
          pageSize,
          ...pickBy(params),
        },
      })
      .json()
      .catch((err) => console.log(err))
  },
  setExpectedBalance: ({ name, expected }) => {
    return fetch
      .post(`expected-balance`, { json: { name, expected } })
      .json()
      .catch((err) => console.log(err))
  },
}

export default balancesApi
