import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'

import filtersReducer from './reducers/filters-reducer'
import settingsReducer from './reducers/settings-reducer'
import botsReducer from './reducers/bots-reducer'

const rootReducer = combineReducers({
  filters: filtersReducer,
  settings: settingsReducer,
  bots: botsReducer,
})

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    // @ts-ignore
    window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({
      trace: true,
      traceLimit: 25,
    })) ||
  compose

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)))

window.store = store
