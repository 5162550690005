import { fetch } from './'

export const balanceLevelingApi = {
  getLevelingRules: (page, pageSize) => {
    return fetch
      .get(`leveling-rules`, { searchParams: { page, pageSize } })
      .json()
      .catch((err) =>
        console.log(err)
      ) || []
  },
  updateRule: (rule) => {
    return fetch
      .post(`leveling-rule/${rule.id}`, { json: rule })
      .json()
  },
  enableRule: (ruleId) => {
    return fetch
      .post(`leveling-rule/${ruleId}/enable`)
      .json()
  },
  disableRule: (ruleId) => {
    return fetch
      .post(`leveling-rule/${ruleId}/disable`)
      .json()
  },
  enableMonitoring: (ruleId) => {
    return fetch
      .put(`monitoring-bl-status/${ruleId}/enable`)
      .json()
  },
  disableMonitoring: (ruleId) => {
    return fetch
      .put(`monitoring-bl-status/${ruleId}/disable`)
      .json()
  },
  listErrors: () => {
    return fetch
      .get(`list-bl-errors`)
      .json()
      .catch((err) =>
        console.log(err)
      ) || []
  },
}
