import Container from '@mui/material/Container'
import s from './Header.module.css'
import { Nav } from './Nav'
import { User } from './User'

const Header = () => {
  return (
    <div className={s.headerContainer}>
      <Container maxWidth='false'>
        <header className={s.header}>
          <Nav />
          <User />
        </header>
      </Container>
    </div>
  )
}

export default Header
