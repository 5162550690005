import { useEffect, useState, useCallback } from 'react'
import { isEqual } from 'lodash'
import { useSessionStorage } from 'hooks/use-session-storage'

import balancesApi from '../../../../api/balances-api'

export const useBalanceLevelingApi = (page, pageSize, params) => {
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [isLastPage, setLastPage] = useState(false)

  const fetchData = useCallback(async () => {
    try {
      setIsFetching(true)
      const filters = {}

      if (params && params.filters && params.filters.id) {
        filters.id = params.filters.id.toUpperCase()
        filters.value = params.filters.value
      }

      const response = await balancesApi.getBalanceLeveling(page, pageSize, params.sortBy, filters)

      setData(response?.transactions || [])
      setLastPage(response?.total_records < pageSize)
    } catch (e) {
      console.error(e)
      setData([])
      setLastPage(true)
    } finally {
      setIsFetching(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, params.sortBy, params.filters, pageSize])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return [data, isFetching, isLastPage]
}

export const PAGE_SIZES = [7, 20, 50, 100, 1000, 2000, 5000, 10000]

export const usePagination = (start = 1) => {
  const [page, setPage] = useState(start)
  const [pageSize, setPageSize] = useSessionStorage('botBalanceLevelingPageSize', PAGE_SIZES[0])

  const decrement = useCallback(() => {
    setPage((prevPage) => {
      if (prevPage > 1) {
        return prevPage - 1
      }

      return prevPage
    })
  }, [])

  const increment = useCallback(() => {
    setPage((prevPage) => prevPage + 1)
  }, [])

  const setStartPage = useCallback(() => {
    setPage(start)
  }, [start])

  const changePageSize = useCallback(
    (e) => {
      setPageSize(e.target.value)
      setStartPage()
    },
    [setPageSize, setStartPage]
  )

  return [page, increment, decrement, setStartPage, pageSize, changePageSize]
}

export const useSort = (setStartPage) => {
  const [params, setParams] = useState({
    sortBy: undefined,
    filters: undefined,
  })

  const handleSortChange = useCallback(
    ([sortBy], [filters]) => {
      if (!isEqual(params.sortBy, sortBy) || !isEqual(params.filters, filters)) {
        setStartPage()
        setParams({ sortBy, filters })
      }
    },
    [params.sortBy, params.filters, setStartPage]
  )

  return [params, handleSortChange]
}
