import { useState, useCallback, useEffect } from 'react'
import { useSnackbar } from 'notistack'

import { seekerRulesApi } from 'api/seeker-rules-api'

export const ACTION = {
  CREATE: 'create',
  UPDATE: 'update',
  REMOVE: 'remove',
}

const displayPercentage = (valueStr) => {
  const num = Number(valueStr)

  return Number((num * 100).toFixed(2))
}

const parsePercentage = (valueNum) => {
  return `${(Number(valueNum) / 100).toFixed(4)}`
}

export const useSeekerRuleApi = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const fetch = useCallback(async () => {
    setLoading(true)
    const res = await seekerRulesApi.list()

    if (!res) {
      setLoading(false)

      return
    }

    const response = res.map(({ attrs, ...data }) => ({ ...attrs, ...data }))

    response.forEach(row => {
      row.max_tax = row.max_tax !== '0' ? displayPercentage(row.max_tax) : 0
    });

    setData(response)
    setLoading(false)
  }, [])

  useEffect(() => {
    fetch()
  }, [fetch])

  const create = useCallback(async (data) => {
    setLoading(true)

    try {
      data.max_tax = data.max_tax !== 0 ? parsePercentage(data.max_tax) : '0'
      await seekerRulesApi.create(data)
      enqueueSnackbar('Rule created', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }

    await fetch()
  }, [fetch, enqueueSnackbar])

  const update = useCallback(async (data) => {
    setLoading(true)

    try {
      data.max_tax = data.max_tax !== 0 ? parsePercentage(data.max_tax) : '0'
      await seekerRulesApi.update(data)
      enqueueSnackbar('Rule updated', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }

    await fetch()
  }, [fetch, enqueueSnackbar])

  const remove = useCallback(async (ruleId, ruleName) => {
    setLoading(true)
    try {
      const { error } = await seekerRulesApi.remove(ruleId)

      if (error) {
        enqueueSnackbar(error || 'Unknown error', { variant: 'error' })
      } else {
        enqueueSnackbar(`Rule ${ruleName} removed`, { variant: 'success' })
      }
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }
    await fetch()
  }, [fetch, enqueueSnackbar])

  return [{ data, fetch, create, remove, update }, loading]
}
