import { useState, useCallback } from 'react'
import { useSnackbar } from 'notistack'

import { settingsActions } from 'redux/reducers/settings-reducer'
import { exchangesApi } from 'api/exchanges-api'

export const EXCHANGE_ACTION = {
  CREATE: 'create',
  UPDATE: 'update',
  REMOVE: 'remove',
}

export const useExchangesApi = (dispatch) => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const fetch = useCallback(async () => {
    setLoading(true)
    const res = await exchangesApi.list()

    dispatch(settingsActions.setAvailableExchanges(res))
    setLoading(false)
  }, [dispatch])

  const update = useCallback(async (exchangeId, data) => {
    setLoading(true)
    try {
      await exchangesApi.update(exchangeId, data)
      enqueueSnackbar(`exchange ${data.name} updated`, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(e.message || 'Unknown error', { variant: 'error' })
    }
    await fetch()
  }, [fetch, enqueueSnackbar])

  const create = useCallback(async (data) => {
    setLoading(true)
    try {
      await exchangesApi.create(data)
      enqueueSnackbar(`exchange ${data.name} created`, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(e.message || 'Unknown error', { variant: 'error' })
    }
    await fetch()
  }, [fetch, enqueueSnackbar])

  const remove = useCallback(async (exchangeId, exchangeName) => {
    setLoading(true)
    try {
      await exchangesApi.remove(exchangeId)
      enqueueSnackbar(`exchange ${exchangeName} removed`, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(e.message || 'Unknown error', { variant: 'error' })
    }
    await fetch()
  }, [fetch, enqueueSnackbar])

  return [{ fetch, update, remove, create }, loading]
}
