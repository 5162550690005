import { createSelector } from 'reselect'

export const networksSelector = createSelector(state => state.settings.availableNetworks, networks => networks.reduce((acc, network) => {
  acc[network.name] = network

  return acc
}, []))

export const networksSelectorById = createSelector(state => state.settings.availableNetworks, networks => networks.reduce((acc, network) => {
  acc[network.id] = network

  return acc
}, []))

export const networksListSelector = createSelector(state => state.settings.availableNetworks, networks => networks)

export const exchangesListSelector = createSelector(
  state => state.settings.availableExchanges,
  networksSelectorById,
  (exchanges, networks) => exchanges.map(exchange => ({ ...exchange, network_name: networks[exchange.network_id]?.name }))
)

export const exchangesByNetworkSelector = createSelector(
  state => state.settings.availableExchanges,
  (exchanges) => exchanges.reduce((acc, exchange) => {
    if (!acc[exchange.network_id]) {
      return {
        ...acc,
        [exchange.network_id]: [exchange],
      }
    }

    acc[exchange.network_id].push(exchange)

    return acc
  }, {})
)
