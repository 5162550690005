import Paper from '@mui/material/Paper'
import { useSelector, useDispatch } from 'react-redux'
import { networksListSelector, exchangesListSelector } from 'redux/selectors/settings'

import { useState, useMemo } from 'react'
import styled from '@emotion/styled'

import Table from 'components/Table/Table'
import { getColumns } from './columns'

import { useExchangesApi } from './hooks'

import { CreateExchangeBtn } from './components/CreateExchangeBtn'
import { ExchangeDialog } from './components/ExchangeDialog'

const ExchangesContainer = styled(Paper)`
  overflow-y: auto;
  margin: 4px;
`;

export const Exchanges = () => {
  const networks = useSelector(networksListSelector)
  const networksMap = useMemo(() => networks.reduce((acc, item) => ({
    ...acc,
    [item.id]: item,
  }), {}), [networks])
  const exchanges = useSelector(exchangesListSelector)
  const dispatch = useDispatch()

  const [selectedExchange, setSelectedExchange] = useState(null)
  const [{ create, update, remove }, loading] = useExchangesApi(dispatch)
  const columns = useMemo(() => getColumns(networksMap, setSelectedExchange), [networksMap, setSelectedExchange])

  return (
    <ExchangesContainer elevation={2} id='exchanges_table'>
      <Table
        title={<CreateExchangeBtn setSelectedExchange={setSelectedExchange} />}
        data={exchanges}
        columns={columns}
        loading={loading}
        initialState={{
          sortBy: [{ id: 'id', desc: true }],
        }}
        enableFilters
      />
      <ExchangeDialog
        value={selectedExchange}
        onClose={() => setSelectedExchange(null)}
        update={update}
        remove={remove}
        create={create}
        networks={networks}
      />
    </ExchangesContainer>
  )
}
