import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import { useForm, Controller } from "react-hook-form"

import { useCallback } from 'react'

import { NETWORK_ACTION } from '../hooks'

const NETWORK_TITLES = {
  [NETWORK_ACTION.CREATE]: 'Create',
  [NETWORK_ACTION.UPDATE]: 'Update',
  [NETWORK_ACTION.REMOVE]: 'Remove',
}

const DEFAULT_VALUE = {
  name: '',
  block_explorer_url: '',
}

const FIELD_OPTIONS = {
  required: true,
  validate: (v) => typeof v === 'string' ? v.trim().length > 1 : v !== null && v !== undefined,
}

export const Network = ({ type, network, save, remove, close }) => {
  const isRemoveForm = type === NETWORK_ACTION.REMOVE

  const { control, handleSubmit, formState: { errors } } = useForm({ defaultValues: { ...DEFAULT_VALUE, ...network } });
  const onSubmit = useCallback(async (data) => {
    await save(data)

    close()
  }, [save, close])

  const removeAndClose = useCallback(async () => {
    await remove(network.id, network.name)
    close()
  }, [network.id, close, remove, network.name])

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={isRemoveForm ? () => {} : handleSubmit(onSubmit)}
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
    >
      <Card sx={{ maxWidth: 310 }}>
        <CardContent>
          <Typography variant="h5" component="div" mb={2} id={'edit_popup_title'}>
            {NETWORK_TITLES[type]} network
          </Typography>
          {isRemoveForm && (
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              You're about to remove <b>{network.name}</b> network. Are you sure?
            </Typography>
          )}
          <Controller
            name="name"
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) =>
              <TextField
                disabled={isRemoveForm || type === NETWORK_ACTION.UPDATE}
                label="name"
                error={!!errors.name}
                helperText={errors.name && "name is invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="block_explorer_url"
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) =>
              <TextField
                disabled={isRemoveForm}
                label="Url"
                error={!!errors.block_explorer_url}
                helperText={errors.block_explorer_url && "block_explorer_url is invalid"}
                {...field}
              />
            }
          />
        </CardContent>
        <CardActions>
          {isRemoveForm
            ? <Button size="small" color='error' id='confirm_button' onClick={removeAndClose}>Confirm</Button>
            : <Button size="small" color='primary' type="submit" id='submit_button'>Submit</Button>
          }
          <Button size="small" onClick={close}>Cancel</Button>
        </CardActions>
      </Card>
    </Box>
  )
}
