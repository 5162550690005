import { settingsActions } from '../reducers/settings-reducer'
import { exchangesApi } from '../../api/exchanges-api'
import networksAPI from '../../api/networks-api'
import { getIsAuth } from '../../storage/user-storage'

export const initializeApp = () => async (dispatch) => {
  if (getIsAuth()) {
    try {
      const [exchanges, networks] = await Promise.all([exchangesApi.list(), networksAPI.get()])

      if (exchanges instanceof Array) {
        dispatch(settingsActions.setAvailableExchanges(exchanges))
      }

      if (networks instanceof Array) {
        dispatch(settingsActions.setAvailableNetworks(networks))
      }
    } catch (e) {
      console.log(e)
    }
  }

  dispatch(settingsActions.setInitialized(true))
}
