import { useState, useCallback } from 'react'
import omit from 'lodash/omit'
import { useSnackbar } from 'notistack'

import { settingsActions } from 'redux/reducers/settings-reducer'
import { networksApi } from 'api/networks-api'

export const NETWORK_ACTION = {
  CREATE: 'create',
  UPDATE: 'update',
  REMOVE: 'remove',
}

export const useNetworksApi = (dispatch) => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const fetch = useCallback(async () => {
    setLoading(true)

    try {
      const res = await networksApi.get()

      if (res) {
        dispatch(settingsActions.setAvailableNetworks(res))
      }
    } finally {
      setLoading(false)
    }
  }, [dispatch])

  const save = useCallback(async (data) => {
    setLoading(true)

    try {
      await networksApi.save(omit(data, 'id'))
      enqueueSnackbar(`network ${data.name} ${data.id ? 'updated' : 'created'}`, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }

    await fetch()
  }, [fetch, enqueueSnackbar])

  const remove = useCallback(async (networkId, networkName) => {
    setLoading(true)
    try {
      const { error } = await networksApi.remove(networkId)

      if (error) {
        enqueueSnackbar(error || 'Unknown error', { variant: 'error' })
      } else {
        enqueueSnackbar(`network ${networkName} removed`, { variant: 'success' })
      }
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }
    await fetch()
  }, [fetch, enqueueSnackbar])

  return [{ fetch, save, remove }, loading]
}
